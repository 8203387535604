@import "@/shared/ui/config/scss/_index";

// @import "@/widgets/layouts/header/index";
// @import "@/widgets/nav/headerNav/index";
// @import "@/widgets/layouts/sidebar/index";
// @import "@/widgets/layouts/main/index";
// @import "@/widgets/layouts/footer/index";
// @import "@/widgets/nav/sidebarNav/index";
// @import "@/features/sidebarForm/index";
// @import "@/widgets/nav/footerNav/index";
// @import "@/shared/ui/popup/_index";

.page-404 .notfound {
  padding-top: 50px;
  padding-bottom: 80px;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }

  &__image {
    max-width: 705px;
    width: 100%;
  }

  &__description {
    p {
      color: #484748;
      text-align: center;
      font-family: "Circe";
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      @media (max-width: 768px) {
        font-size: 26px;
      }
      @media (max-width: 560px) {
        font-size: 22px;
      }
    }
  }

  &__button {
  }
}
